import request from "@/utils/request";
const prefix = '/overview'
//待办事项
// export const todoList = (
//     callback
// ) => {
//
//     return request(`${prefix}/todoList`, { params:{},method: 'get'}, callback)
// }
export const todoList = (
    callback
) => {

    return request('/dashboard/todoList', { params:{},method: 'get'}, callback)
}
//今天昨天营业额
// export const normalTurnover = (
//     callback
// ) => {
//
//     return request(`${prefix}/normalTurnover`, { params:{},method: 'get'}, callback)
// }
export const normalTurnover = (
    callback
) => {

    return request("dashboard/normalTurnover", { params:{},method: 'get'}, callback)
}
//本周本月营业额
// export const variationTurnover = (
//     {
//         type
//     },
//     callback
// ) => {
//     const data = {
//         type
//     }
//     return request(`${prefix}/variationTurnover`, { params:data,method: 'get'}, callback)
// }

export const variationTurnover = (
    {
        type
    },
    callback
) => {
    const data = {
        type
    }
    return request('dashboard/variationTurnover', { params:data,method: 'get'}, callback)
}



//折线图
// export const chartTurnover = (
//     {
//         type,
//         storeCode,
//         userCode,
//         dType
//     },
//     callback
// ) => {
//     const data = {
//         type,
//         storeCode,
//         userCode,
//         dType
//     }
//     return request(`${prefix}/chartTurnover`, { params:data,method: 'get'}, callback)
// }

export const chartTurnover = (
    {
        type,
        storeCode,
        userCode,
        dType
    },
    callback
) => {
    const data = {
        type,
        storeCode,
        userCode,
        dType
    }
    return request('/dashboard/chartTurnover', { params:data,method: 'get'}, callback)
}