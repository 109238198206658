import request from "@/utils/request";
const prefix = '/inform'

//获取系统公告
// export const systemNotice = (
//     callback
// ) => {
//
//     return request(`${prefix}/systemNotice`, { params:{},method: 'get'}, callback)
// }

export const systemNotice = (
    callback
) => {

    return request(`${prefix}/systemNotice`, { params:{},method: 'get'}, callback)
}