<template>
  <div class="orders_received">
      <div class="orders_title">
          <div class="title_left">订单统计</div>
<!--          <div class="title_right">查看</div>-->
      </div>
      <el-row>
        <el-col class="operating">
          <div class="left">
            <div>
              <span>统计类型</span>
              <el-select v-model="valueType" placeholder="请选择" size="mini" class="statisticsType" @change="statistics">
                <el-option
                    v-for="item in optionsType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
<!--            <div>-->
<!--              <span>查看类型</span>-->
<!--              <el-select v-model="valuePlatformType" placeholder="请选择" size="mini" class="platformType">-->
<!--                <el-option-->
<!--                    v-for="item in optionsPlatformType"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--              <el-select v-model="valuePlatform" placeholder="请选择" size="mini" class="platform">-->
<!--                <el-option-->
<!--                    v-for="item in optionsPlatform"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->
          </div>
          <div class="right">
            <el-button class="btn" size="mini" @click="date(1)">日数据</el-button>
            <el-button class="btn" size="mini" @click="date(2)">周数据</el-button>
            <el-button class="btn" size="mini" @click="date(3)">月数据</el-button>
          </div>
        </el-col>
          <el-col :span="24" id="myChart" :style="{ height: '450px' }">

          </el-col>
      </el-row>
  </div>
</template>

<script>
const echarts = require('echarts/lib/echarts');
require('echarts/lib/component/grid');
require('echarts/lib/chart/line');
import { TooltipComponent } from 'echarts/components';
echarts.use([TooltipComponent]);
import { chartTurnover } from '@/api/overview'
export default {
  data () {
    return {
      valueType:1, //统计类型
      valuePlatformType:1,//平台类型
      valuePlatform:1,//平台
      day:1,   //日子
      optionsType:[
        {
          value:1,
          label:'营业额'
        },
        {
          value:2,
          label:'订单数'
        },
        {
          value:3,
          label:'订单件数'
        },
      ], //统计类型
      optionsPlatformType:[
        {
          value:1,
          label:'平台'
        },
        {
          value:2,
          label:'账号'
        },
        {
          value:3,
          label:'店铺'
        },
      ], //平台类型
    optionsPlatform:[
        {
          value:1,
          label:'亚马逊'
        },
        {
          value:2,
          label:'shopify'
        },
        {
          value:3,
          label:'速卖通'
        },
      ], //平台
      data:[],   //数据
      statisticsDate:[],  //日期
    }
  },
  created () {
    this.getLineChart()
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.drawLine()
    //   console.log(this.drawLine())
    // });
  },
  methods: {
    // 日期
    date(i){
      this.day = i
      this.getLineChart()

      this.$nextTick(() => {
        this.drawLine()
      });
    },
    async getLineChart(){
      try {
        this.data = []
        this.statisticsDate =[]
        const { data } = await chartTurnover(
            {
              type:this.valueType,
              dType:this.day
            }
        )
        console.log(data)
        for (let i = 0; i<data.length; i++){
          if(this.valueType === 1) {
            this.data.push(parseFloat(data[i].final_money))
            this.statisticsDate.push(data[i].statistics_date)

          } else if(this.valueType === 2) {
            this.data.push(parseFloat(data[i].order_amount))
            this.statisticsDate.push(data[i].statistics_date)
          } else if(this.valueType === 3) {
            this.data.push(parseFloat(data[i].order_product_amount))
            this.statisticsDate.push(data[i].statistics_date)
          }
        }

        this.drawLine()
      } catch (e) {
        console.log(e)
      }
    },
    statistics(){
      this.getLineChart()
    },
       drawLine(){

        // 基于准备好的dom，初始化echarts实例
        // let myChart = this.$echarts.init(document.getElementById('myChart'))
        let chartDom = document.getElementById('myChart');
        let myChart = echarts.init(chartDom);
        // 绘制图表
     let option = {

          xAxis: {
              type: 'category',
              // data: ['2021.06.19', '2021.07.20', '2021.07.21']
              data: this.statisticsDate,

          },
          yAxis: {
              type: 'value'
          },
          series: [{
              // data: [100.00, 2819.11, 100.00],
              data: this.data,
              type: 'line'
          }],

       tooltip : {
         trigger: 'axis',
         axisPointer: {
           type: 'cross',
           label: {
             backgroundColor: '#6a7985'
           }
         }
       }

     };
       option && myChart.setOption(option);
         window.onresize = function () {
           myChart.resize();
         };
    }
  },
  watch:{}
}
</script>

<style scoped lang="less">
.orders_received{
    margin-top: 20px;
    .orders_title{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .title_left{
             font-size: 16px;
             font-weight: 500;
             height: 22px;
             line-height: 22px; 
        }
        .title_right{
            font-size: 14px;
            color:#FA4A14;
            cursor: pointer;
        }
    }
}
.operating{
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
  .left{
    display: flex;
    font-size: 14px;
    color: #333333;
  }
  .statisticsType {
    margin-left: 5px;
    margin-right: 40px;
    width: 150px;
    height: 26px;
  }
  .platformType {
    margin-left: 5px;
    width: 80px;
    height: 26px;
  }
  .platform {
    margin-left: 5px;
    margin-right: 40px;
    width: 150px;
    height: 26px;
  }

}
</style>
