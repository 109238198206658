<template>
  <div class="turnover">
       <div class="turnover_title">
          营业额
      </div>
      <div class="turnover_body">
          <el-row :gutter="20">
             <el-col :span="8" >
                  <el-card shadow="always" class="turnover_today">
                    <div class="today_title">
                        今日营业额
                    </div>
                    <div class="today_body">
                        <div class="body_left">
                        <div class="today_item">￥{{todaySTurnover.todayMoney || 0 }}</div>
                        </div>
                        <div class="body_right">
                        <div class="today_item">订单数 <span class="text">{{todaySTurnover.todayOrderAmount || 0 }}</span></div>
                        <div class="today_item">订单件数 <span class="text">{{todaySTurnover.todayProductionAmount || 0 }}</span></div>
                        </div>
                    </div>
                  </el-card>
              </el-col>
             <el-col :span="8">
                  <el-card shadow="always" class="turnover_today">
                    <div class="today_title">
                        昨日营业额
                    </div>
                    <div class="today_body">
                        <div class="body_left">
                        <div class="today_item">￥{{yesterdaySTurnover.yesterdayMoney || 0 }}</div>
                        </div>
                        <div class="body_right">
                        <div class="today_item">订单数 <span class="text">{{yesterdaySTurnover.yesterdayOrderAmount || 0 }}</span></div>
                        <div class="today_item">订单件数 <span class="text">{{yesterdaySTurnover.yesterdayProductionAmount || 0 }}</span></div>
                        </div>
                    </div>
                  </el-card>
              </el-col>
             <el-col :span="8">
                   <el-card shadow="always" class="turnover_today">
                    <div class="today_title">
                       <el-select v-model="la" placeholder="" size="mini" @change="operate">
                         <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value" ></el-option>
                       </el-select>
                    </div>
                    <div class="today_body">
                        <div class="body_left">
                        <div class="today_item">￥{{weeklyTurnover.totalMoney || 0 }}</div>
                        </div>
                        <div class="body_right">
                        <div class="today_item">订单数 <span class="text">{{weeklyTurnover.orderAmount}}</span></div>
                        <div class="today_item">订单件数 <span class="text">{{weeklyTurnover.productionAmount}}</span></div>
                        </div>
                    </div>
                  </el-card>
              </el-col>
          </el-row>
      </div>
  </div>
</template>

<script>
import { normalTurnover, variationTurnover } from '@/api/overview'
export default {
  data () {
    return {
        la:1,
        options:[
            {
                value:1,
                label:'本周营业额'
            },
            {
                value:2,
                label:'本月营业额'
            },
        ],
      todaySTurnover: {
        todayMoney:0,
        todayOrderAmount:0,
        todayProductionAmount:0
      },  //今天营业额
      yesterdaySTurnover:{
        todayMoney:0,
        todayOrderAmount:0,
        todayProductionAmount:0
      },  //昨日营业额
      weeklyTurnover:{
        totalMoney:0,
        orderAmount:0,
        productionAmount:0
      }, //周、月营业额
    }
  },
  created () {
    this.sumOfBusiness()
    this.thisWeekThisMonth()
  },
  mounted () {

  },
  methods: {
    async sumOfBusiness(){
      try{
        const { data } = await normalTurnover()
        console.log(data)
        this.todaySTurnover = data.today
        this.yesterdaySTurnover = data.yesterday
      } catch (e){
        this.$message.error(e)
      }
    },
    //周、月营业额
    operate(){
      this.thisWeekThisMonth()
    },
    async thisWeekThisMonth(){
      try {
        const { data } = await variationTurnover(
            {
              type: this.la
            }
        )
        this.weeklyTurnover.totalMoney = data.totalMoney
        this.weeklyTurnover.orderAmount = data.orderAmount
        this.weeklyTurnover.productionAmount = data.productionAmount
      } catch (e){
        this.$message.error(e)
      }
    },
  }
}
</script>

<style scoped lang="less">
/deep/.el-input--suffix{
    .el-input__inner{
        padding: 0;
        border: 0;
        height: 25px;
        line-height: 25px;
        font-size: 16px;
    }
}
.turnover{
    color: #333;
    .turnover_title{
       font-size: 16px;
       font-weight: 500;
       height: 22px;
       line-height: 22px;
       margin-bottom: 10px;
    }
    .turnover_body{
    /deep/.el-card__body{
         padding: 20px 30px;
       }
       .text{
           color: #333;
       }
       .turnover_today{
           font-size: 16px;
           color: #666666;
           height: 132px;
           overflow: hidden;
           .today_title{
               margin-bottom: 20px;
           }
           .today_body{
               display: flex;
               justify-content: space-between;
               .body_left{
                   color: #333;
                   font-size: 21px;
                   font-weight: 600;
               }
               .body_right{
                   text-align: right;
                   .today_item{
                       margin-bottom: 10px;
                   }
               }
           }
       }
    }
}
</style>
