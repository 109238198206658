<template>
  <div class="backlog">
      <div class="backlog_title">
          待办事项
      </div>
      <div class="backlog_body">
          <el-row :gutter="20">
             <el-col :span="8" >
                  <el-card shadow="always" class="backlog_orders">
                    <div class="orders_title">
                        平台订单
                    </div>
                    <div class="orders_body">
                        <div class="orders_item">待付款 <span class="text">{{platform.unPayOrderAmount}}</span></div>
                        <div class="orders_item">待生产 <span class="text">{{platform.unProductOrderAmount}}</span></div>
                        <div class="orders_item">生产中 <span class="text">{{platform.ProductingOrderAmount}}</span></div>
                        <div class="orders_item">已发货 <span class="text">{{platform.deliverOrderAmount}}</span></div>
                    </div>
                  </el-card>
              </el-col>
<!--             <el-col :span="8">-->
<!--                  <el-card shadow="always" class="backlog_orders">-->
<!--                    <div class="orders_title">-->
<!--                        用户订单-->
<!--                    </div>-->
<!--                    <div class="orders_body">-->
<!--                        <div class="orders_item">订单数 <span class="text">12</span></div>-->
<!--                        <div class="orders_item">未发货 <span class="text">12</span></div>-->
<!--                        <div class="orders_item">已发货 <span class="text">12</span></div>-->
<!--                    </div>-->
<!--                  </el-card>-->
<!--              </el-col>-->
<!--             <el-col :span="8">-->
<!--                  <el-card shadow="always" class="notice">-->
<!--                    <div class="notice_title">-->
<!--                        <div class="title_left">公告</div>-->
<!--&lt;!&ndash;                        <div class="title_right" @click="viewAll">查看全部</div>&ndash;&gt;-->
<!--                    </div>-->
<!--                    <div class="notice_item" v-for="(item,index) in systemNotification" :key="index" @click="viewAnnouncement(item.id)">-->
<!--                        <div class="item_left" v-if="!!item && item.title">{{item.title}}</div>-->
<!--&lt;!&ndash;                        <div class="item_right">2021-05-08</div>      &ndash;&gt;-->
<!--                    </div>-->
<!--                  </el-card>-->
<!--              </el-col>-->
          </el-row>
      </div>
  </div>
</template>

<script>
import { todoList } from '@/api/overview'
import { systemNotice } from '@/api/inform'
export default {
  data () {
    return {
      platform:{
        unPayOrderAmount:0,
        unProductOrderAmount:0,
        ProductingOrderAmount:0,
        deliverOrderAmount:0
      },  //平台订单
      systemNotification:[],  //系统公告
    }
  },
  created () {
    this.upcoming()
    // this.announcement()
  },
  mounted () {

  },
  methods: {
    async upcoming(){
      try {
        const { data } = await todoList()
        this.platform = data
      } catch (e){
        console.log(e)
      }
    },
  //  获取公告
    async announcement(){
      try {
        this.systemNotification = []
        const { data, msg } = await systemNotice()
        for (let i = 0; i<2; i++){
          this.systemNotification.push(data[i])
        }
      } catch (e){
        console.log(e)
      }
    },
  //  查看公告
    viewAnnouncement(id){
      console.log(id)
      window.location.href = process.env.VUE_APP_HOME_URL+`announcement/${id}`
    },
  }
}
</script>

<style scoped lang="less">
.backlog{ 
//    background: #F7F8FA;
   color: #333;
   margin-bottom: 24px;
   .backlog_title{
       font-size: 16px;
       font-weight: 500;
       height: 22px;
       line-height: 22px;
       font-weight: 500;
   }
   .backlog_body{
       margin-top: 10px;
       /deep/.el-card__body{
           padding: 20px 30px;
       }
       .text{
           color: #333;
       }
       .backlog_orders{
           font-size: 16px;
           color: #666666;
           height: 132px;
           overflow: hidden;
           .orders_title{
               margin-bottom: 20px;
           }
           .orders_body{
               display: flex;
               flex-wrap: wrap;
               font-size: 14px;
               .orders_item{
                   margin-right: 90px;
                   margin-bottom: 10px;
               }
           }
           .notice_item{
               display: flex;
               justify-content: space-between;
               .item_left{
                   font-size: 14px;
               }
               .item_right{
                   font-size: 12px;
               }
           }
       }
        .notice{
           font-size: 16px;
           color: #666666;
           height: 132px;
           overflow: hidden;
           .notice_title{
               margin-bottom: 20px;
               display: flex;
               justify-content: space-between;
               .title_right{
                   font-size: 12px;
                   color: #FA4A14;
                   cursor: pointer;
               }
           }
           .notice_item{
               display: flex;
               justify-content: space-between;
              cursor: pointer;
               margin-bottom: 10px;
               .item_left{
                   font-size: 14px;
               }
               .item_right{
                   font-size: 12px;
               }
           }
       }
   }
}
</style>
