<template>
  <div class="index_box">
    <back-log />
    <turn-over />
    <orders-received />
  </div>
</template>

<script>
import BackLog from './compoents/BackLog.vue'
import TurnOver from './compoents/TurnOver.vue'
import OrdersReceived from './compoents/OrdersReceived.vue'

export default {
  name: "Index",
  components: {BackLog, TurnOver, OrdersReceived},
  methods: {
  
    
  },
  mounted() {

  }
}
</script>

<style scoped lang="less">

</style>
